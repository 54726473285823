import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import type { UniversalLinkProps } from "library/Loader/UniversalLink"
import UniversalLink from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

type TertiaryButtonProps = {
	dark?: boolean
} & UniversalLinkProps

export default function Tertiary({
	dark = false,
	children,
	...props
}: TertiaryButtonProps) {
	return (
		<Wrapper {...props}>
			<Inner $dark={dark}>
				<Arrow />
				{children}
			</Inner>
		</Wrapper>
	)
}

const Inner = styled.div<{ $dark: boolean }>`
	${textStyles.button5}
	position: relative;
	display: flex;
	align-items: center;
	color: ${({ $dark }) => ($dark ? colors.white : colors.black)};
	transition: transform 0.5s;

	svg {
		path {
			fill: ${({ $dark }) => ($dark ? colors.white : colors.black)};
		}
	}

	${fresponsive(css`
		transform: translateX(-17px);
		gap: 8px;
	`)}
`

const Wrapper = styled(UniversalLink)`
	width: fit-content;
	overflow: hidden;

	&:hover {
		${Inner} {
			transform: translateX(0);
		}
	}
`

const Arrow = styled(ArrowSVG)`
	height: auto;

	${fresponsive(css`
		width: 9px;
	`)}
`
