import type React from "react"
import {
	createContext,
	startTransition,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"

const NavContext = createContext<{
	menuDark: boolean
	setMenuDark: React.Dispatch<React.SetStateAction<boolean>>
	paused: boolean
	setPaused: React.Dispatch<React.SetStateAction<boolean>>
	removeNav: boolean
	setRemoveNav: React.Dispatch<React.SetStateAction<boolean>>
}>({
	menuDark: false,
	setMenuDark: () => false,
	paused: false,
	setPaused: () => false,
	removeNav: false,
	setRemoveNav: () => false,
})

/**
 * access the current header color & theme
 * @param options
 * @param options.menuDark whether the menu should be dark text or light text
 * @returns the current header color & theme
 */
export const useNavConfig = (options?: {
	menuDark?: boolean
	paused?: boolean
	removeNav?: boolean
}) => {
	const { menuDark, setMenuDark, paused, setPaused, removeNav, setRemoveNav } =
		useContext(NavContext)

	const newMenuDark = options?.menuDark
	const newPaused = options?.paused
	const newRemoveNav = options?.removeNav

	useEffect(() => {
		if (typeof newMenuDark === "boolean")
			startTransition(() => setMenuDark(newMenuDark))
		if (typeof newPaused === "boolean")
			startTransition(() => setPaused(newPaused))
		if (typeof newRemoveNav === "boolean")
			startTransition(() => setRemoveNav(newRemoveNav))
	}, [
		newMenuDark,
		setMenuDark,
		newPaused,
		setPaused,
		newRemoveNav,
		setRemoveNav,
	])

	return { menuDark, paused, removeNav }
}

type Props = {
	children: React.ReactNode
}

export default function NavProvider({ children }: Props) {
	const [menuDark, setMenuDark] = useState<boolean>(false)
	const [paused, setPaused] = useState<boolean>(false)
	const [removeNav, setRemoveNav] = useState<boolean>(false)

	const values = useMemo(
		() => ({
			menuDark,
			setMenuDark,
			paused,
			setPaused,
			removeNav,
			setRemoveNav,
		}),
		[menuDark, paused, removeNav],
	)

	return <NavContext.Provider value={values}>{children}</NavContext.Provider>
}
